import Container from "../Container";
import { bannerData } from "../../resources/Banner";
import Typewriter from "typewriter-effect";
import "react-circular-progressbar/dist/styles.css";
import { Slide } from "react-awesome-reveal";

export default function BannerSection() {
  return (
    <div className="mt-[5rem] bg-transparent min-h-fit">
      <Container>
        <div className="flex flex-col md:flex-row">
          <div className="md:basis-7/12 text-3xl sm:text-3xl  lg:text-5xl w-full lg:mt-[10rem] md:w-auto font-semibold">
            <Slide>
              <div className="flex flex-col gap-2 text-3xl sm:text-3xl md:text-5xl lg:text-7xl mx-auto justify-center justify-items-center align-middle">
                <p>{bannerData.bannerTextOne}</p>
                <p>{bannerData.bannerTextTwo}</p>
              </div>
            </Slide>
            <div className="md:py-10 type-text">
              <div className="hidden md:flex flex-wrap items-center flex-row md:text-xl lg:text-2xl font-normal ">
                <p>
                  &lt;<span className="text-[#CE9FFC]">code</span>&gt;
                </p>
                <p>{bannerData.typeText}</p>
                <p className="text-[#FAFAFC] pl-2">
                  <span>
                    <Typewriter
                      options={{
                        strings: [
                          " Organize your data ",
                          " Build Relationship among data ",
                          " Design Interactive Visuals.  ",
                          " Debug your report and code ",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </span>
                </p>
                <p>
                  &lt;<span className="text-[#CE9FFC]">/code</span>&gt;
                </p>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center justify-items-center align-middle md:basis-5/12">
            <img
              className="w-3/5 h-3/5 md:w-auto md:h-auto"
              src={require(`../../assets/images/${bannerData["profile_image_path"]}`)}
              alt=""
            />
          </div>
          <div className="md:hidden py-5 type-text">
            <div className="flex md:hidden justify-center justify-items-center align-middle flex-row  text-sm sm:text-xl md:text-2xl font-normal ">
              <p>
                &lt;<span className="text-[#CE9FFC]">code</span>&gt;
              </p>
              <p>{bannerData.typeText}</p>
              <p className="text-[#FAFAFC] pl-2">
                <span>
                  <Typewriter
                    options={{
                      strings: [
                        " Coach",
                        " Mentor",
                        " Host",
                        " Advisor",
                        " Expert",
                      ],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </p>
              <p>
                &lt;<span className="text-[#CE9FFC]">/code</span>&gt;
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
