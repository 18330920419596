import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Container from "../Container";
import { Education } from "../../resources/Education";
import { workHistory } from "../../resources/Work";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

export default function ExperienceSection() {
  return (
    <div className="pb-[5em] mt-[3rem] md:mt-[5rem]">
      <Container>
        <div className="flex flex-col">
          <div>
            <div>
              <Slide>
                <p className="text-2xl font-bold py-10">Work History</p>
              </Slide>
            </div>
            <div>
              <VerticalTimeline
                className="w-full ml-0"
                layout="1-column-left"
              >
                {workHistory.map((element, index) => {
                  return (
                    <VerticalTimelineElement
                      contentStyle={{
                        background: "rgba(211, 211, 211, 0.5)",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgba(211, 211, 211, 0.5)",
                      }}
                      iconStyle={{ background: "#B99FC6" }}
                    >
                      <div className="text-3xl">
                        <p className="text-[#3B2667]">{element.role}</p>
                      </div>
                      <p className="font-thin">{element.company}</p>
                      <button className="btn-gradient px-4 py-1 rounded-full my-5">
                        {element.duration}
                      </button>
                      <p className="text-base">{element.responsibility}</p>
                      <p className="text-base mt-5">{element.daywork}</p>
                      <p className="text-base mt-5">{element.content1}</p>
                      <p className="text-base mt-5">{element.content2}</p>
                      <Link to={`${element.url}`} target="_blank">
                        <div className="flex flex-row pt-5 cursor-pointer">
                          <div>
                            <p className="text-md md:text-2xl lg:text-2xl">
                              {element.cta}
                            </p>
                          </div>
                          <div>
                            {element.cta ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                class="w-10 h-12"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                              </svg>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Link>
                    </VerticalTimelineElement>
                  );
                })}
              </VerticalTimeline>
            </div>
          </div>
          <div>
            <div className>
              <Slide>
                <p className="text-2xl font-bold py-10">
                  Education & Certification
                </p>
              </Slide>
            </div>
            <div>
              <VerticalTimeline
                className="w-full ml-0"
                layout="1-column-left"
              >
                {Education.map((element, index) => {
                  return (
                    <VerticalTimelineElement
                      contentStyle={{
                        background: "rgba(211, 211, 211, 0.5)",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgba(211, 211, 211, 0.5)",
                      }}
                      iconStyle={{ background: "#B99FC6" }}
                    >
                      <div className="text-3xl">
                        <p className="text-[#3B2667]">{element.title}</p>
                      </div>
                      <p className="font-thin">{element.certificationName}</p>
                      <button className="btn-gradient px-4 py-1 rounded-full my-5">
                        {element.duration}
                      </button>
                      <p className="mb-3">{element.content}</p>
                      <Link to={`${element.url}`} target="_blank">
                        <div className="flex flex-row pt-5 cursor-pointer">
                          <div>
                            <p className="text-md md:text-2xl lg:text-2xl">
                              {element.cta}
                            </p>
                          </div>
                          <div>
                            {element.cta ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                class="w-10 h-12"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                                />
                              </svg>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Link>
                    </VerticalTimelineElement>
                  );
                })}
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
