import Container from "../Container";
import { aboutMe, aboutWork, guest, story } from "../../resources/AboutMe";
import aboutMeLogo from "../../assets/images/aboutmelogo.png";
import { Slide } from "react-awesome-reveal";

export default function AboutMeSection({ onDataFromChild }) {
  const aboutdata = aboutMe.about;
  const aboutWorkData = aboutWork.whatIdo;
  const storyData = story.myStory;

  const sendDataToParent = () => {
    const data = "Contacts";
    onDataFromChild(data);
  };

  return (
    <div className="mt-[5rem] md:mt-[7rem] pb-[5em]">
      <Container>
        <div className="flex flex-col md:flex-row">
          <div className="md:basis-2/3 lg:basis-8/12">
            <Slide>
              <div className="pb-7">
                <p className="text-2xl md:text-4xl font-semibold pb-7">
                  Who am I?
                </p>
                {aboutdata.map((element, index) => {
                  return (
                    <p
                      className="text-xl md:text-xl lg:text-2xl font-normal pb-4"
                      key={index}
                    >
                      {element}
                    </p>
                  );
                })}
              </div>
            </Slide>
            <Slide>
              <div>
                <p className="text-2xl mt-5 md:mt-6 md:text-4xl font-semibold pb-7">
                  What I do?
                </p>
                {aboutWorkData.map((element, index) => {
                  return (
                    <p
                      className="text-xl md:text-xl lg:text-2xl font-normal pb-3"
                      key={index}
                    >
                      {element}
                    </p>
                  );
                })}
              </div>
            </Slide>
          </div>

          <Slide>
            <div className="flex justify-center justify-items-center align-middle mx-auto md:basis-2/3 lg:basis-4/12">
              <img className="" src={aboutMeLogo} alt="" />
            </div>
          </Slide>
        </div>
        <div>
          <Slide>
            <p className="text-3xl md:text-5xl font-semibold text-center mt-[7rem] mb-5">
              My Story
            </p>
          </Slide>
          <Slide>
            <div className="w-full md:w-10/12 mx-auto mt-10">
              {storyData.map((element, index) => {
                return (
                  <p
                    key={index}
                    className="text-sm sm:text-l md:text-xl font-light leading-8 pb-4"
                  >
                    {element}
                  </p>
                );
              })}
            </div>
          </Slide>
        </div>
        <Slide direction="up">
          <div className="sm:mt-[8rem]">
            <div className="mx-auto w-full sm:w-3/5 my-10">
              <p className="text-2xl sm:text-4xl md:text-5xl font-semibold text-center">
                {guest.title}
              </p>
            </div>
            <div className="mx-auto my-5 w-full sm:w-9/12">
              <p className="text-sm md:text-xl font-light text-center">
                {guest.content}
              </p>
            </div>
            <div className="mx-auto mt-10 w-full sm:w-1/2 flex justify-center justify-items-center align-middle">
              <button
                onClick={sendDataToParent}
                className="mx-auto mt-10 w-full md:w-1/2 px-5 py-2 rounded-full text-2xl btn-gradient"
              >
                Lets connect
              </button>
            </div>
          </div>
        </Slide>
      </Container>
    </div>
  );
}
