import React from "react";
import Container from "../Container";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
export default function Navbar() {
  const handleExploreClick = () => {
    const mainContentSection = document.getElementById("mainContentSection");
    if (mainContentSection) {
      mainContentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container>
      <div className="flex flex-row justify-between mt-10 h-full ">
        <div className="flex flex-row md:gap-3 md:basis-6/12 w-[50%] md:w-full">
          <Link to={"https://biacademy.in/"} target="_blank">
            <img src={logo} alt={logo} className="w-[70%] md:w-full" />
          </Link>

          <div className="flex flex-col">
            <p className="text-white body md:text-xl text-xs font-semibold">
              BI ACADEMY
            </p>
            <p className="text-white body md:text-base text-[10px] ">
              BY SHRAVARI PATIL
            </p>
          </div>
        </div>
        <div className="flex flex-row p-2 gap-2 h-full rounded-full border-[0.1rem]  border-white w-[50%] sm:basis-3/6 md:basis-2/5 lg:basis-3/12">
          <Link
            to={"https://biacademy.in/"}
            className="basis-1/2"
            target="_blank"
          >
            <p className="text-white text-[10px] md:text-base rounded-xl border-white border-[0.1rem]  basis-1/2 text-center cursor-pointer">
              Bi Academy
            </p>
          </Link>

          <p
            className="text-white text-[11px] md:text-base rounded-xl btn-gradient basis-1/2 text-center cursor-pointer"
            onClick={handleExploreClick}
          >
            Explore
          </p>
        </div>
      </div>
    </Container>
  );
}
