import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Footer from "./components/Footer/Footer";
export default function App() {

  return (
      <div>
        <Routes>
        <Route path="/" element={<Home/>} />
      </Routes>
      <Footer/>
      </div>
  );
}
