import Navbar from "../components/Home/NavBar";
import BannerSection from "../components/Home/BannerSection";
import MainContent from "../components/Home/MainContent";
import Rating from "../components/Home/Ratings";
import { Zoom } from "react-awesome-reveal";
import ScrollToTop from "react-scroll-up";

export default function Home() {
  return (
    <div>
      <Navbar />
      <BannerSection />
      <Zoom>
        <Rating />
      </Zoom>
      <MainContent />
      <ScrollToTop
          style={{
            backgroundColor: "#CE9FFC",
            border: "1px solid white",
            borderRadius: "10px",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          duration={500}
          showUnder={160}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-8 h-8"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18"
            />
          </svg>
        </ScrollToTop>
    </div>
  );
}
