const workHistory = [
  {
    role: "Power BI Developer",
    company: "Reliance General Insurance",
    responsibility:
      "As a Power BI Consultant, my expertise includes Data Cleaning, Data Modeling, Report Building, Visualization, Power BI Dashboard, Power BI Service, Dataset Refresh, RLS Security, MySQL, and many more",
    daywork:
      "I created daily booking reports using Power BI, which relied on data from sources such as Excel and SQL. These reports offered insights into key performance indicators, such as month-on-month growth for each segment within the company and how they performed against their targets. To ensure data privacy and security have implemented Row Level security to restrict access to specific data. Lastly have automated these reports by using a scheduled refresh.",
    duration: "Jun 2022 - Present",
    cta: "RELIANCE GENERAL INSURANCE",
    url: "https://www.reliancegeneral.co.in/insurance/home.aspx",
  },
  {
    role: "Power BI Developer",
    company: "Freelance",
    responsibility:
      "Over the past year, you have gained valuable experience in freelancing by creating more than 10 dashboards across various domains such as B2B sales, B2C, HR, hospitals, and COVID. You have demonstrated a comprehensive skillset by working from end to end in the dashboard creation process. This includes gathering requirements, cleaning and modeling data, creating DAX queries, and visualizing the data. Your ability to work with various data sources, such as Excel, SQL, SharePoint, and the web, has allowed you to deliver high-quality work to your clients. Additionally, your expertise in creating visually appealing and interactive dashboards has helped you to provide engaging and insightful presentations of complex data sets. Overall, your one-year experience in freelancing has been focused on data visualization and dashboard creation, with a strong emphasis on delivering end-to-end solutions to your clients.",

    duration: "May 2021 - Jun 2022",
    cta: "RECOMMENDATION",
    url: "https://shravari.com/",
  },
  {
    role: "WhitehatJr",
    company: "Curriculum Developer",
    responsibility:
      "WhitehatJr is an Indian ed-tech startup and online tutoring firm that teaches coding to kids via 1 - to 1 live video classes.",
    daywork:
      "Creating fun and interactive Machine Learning Artificial Intelligence, and web development activities.",
    content1:
      "Created class curriculums and documents which were used by the teachers to train students.",
    content2:
      "Provided teacher training on the curriculum and on how to take classes",
    duration: "Apr 2020 - Jun 2021",
    cta: "WHITE HAT JR",
    url: "https://www.whitehatjr.com/",
  },
];

export { workHistory };
