const Education = [
  {
    title: "Mumbai University",
    certificationName: "Mumbai",
    content:
      "B.E in Computer Engineering from SES GOI FE College of Engineering, Karjat",
    duration: "Aug 2014 - May 2017",
    cta: "KNOM MORE",
    url: "https://beshley.com/import/arter/01/uploads/2020/09/certificate.jpg",
  },
];

export { Education };
