import React from "react";
import Container from "../Container";
import CountUp from "react-countup";

export default function Rating() {
  const data = [
    {
      ratingno: "3",
      ratingtext: "Year Experience",
    },
    {
      ratingno: "13",
      ratingtext: "Completed Projects",
    },
    {
      ratingno: "12",
      ratingtext: "Happy customers",
    },
    {
      ratingno: "20",
      ratingtext: "Honors and Awards",
    },
  ];

  return (
    <div className="w-full my-10">
      <Container>
        <div className="grid grid-cols-2 rounded-lg pb-3 w-full sm:grid-cols-2 sm:pb-3 md:grid-cols-4 xl:w-full mx-auto xl:pb-3 md:pb-3 lg:pb-2 xl:pb-auto  mt-5 md:rounded-full xl:mx-auto bg-btn-gradient">
          {data.map((element, index) => {
            return (
              <div
                className="grid grid-rows-2 grid-flow-col m-auto lg:mt-4 md:mt-4 sm:mt-4 mt-4 xl:mt-5"
                key={index}
              >
                <p className="text-white body text-center xl:text-4xl lg:text-3xl md:text-2xl sm:text-2xl text-lg font-semibold">
                  {index === 3 ? (
                    <CountUp
                      end={parseInt(element.ratingno)}
                      duration={4}
                      suffix="+"
                    />
                  ) : (
                    <CountUp
                      end={parseInt(element.ratingno)}
                      duration={4}
                      suffix="+"
                    />
                  )}
                </p>
                <p className="text-white sm:text-lg md:text-lg lg:text-xl xl:text-2xl body text-sm">
                  {element.ratingtext}
                </p>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}
