import { Slide } from "react-awesome-reveal";
import Offerings from "./Offerings";
export default function MyServices() {
  return (
    <div className="pb-[5em] mt-[2rem]">
      <Slide>
        <Offerings />
      </Slide>
    </div>
  );
}
