import Container from "../Container";
import { projectData } from "../../resources/projects";
import ProjectsCard from "./ProjectsCard";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Slide } from "react-awesome-reveal";


export default function ProjectSection() {
  return (
    <div className="w-full pb-[5em] mt-20">
      <Container>
        <div className="mt-12 p-4 ">
          <Slide>
            <div className="text-center my-10 pb-10">
              <h1 className="text-3xl sm:text-5xl font-semibold">Portfolio</h1>
            </div>
          </Slide>
          <Slide>
            <Carousel
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${
                      hasPrev ? "absolute" : "hidden"
                    } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-100 hover:opacity-30 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <AiOutlineArrowLeft className="w-9 h-9 text-white" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasNext) => {
                return (
                  <div
                    className={`${
                      hasNext ? "absolute" : "hidden"
                    } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-100 hover:opacity-30 cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <AiOutlineArrowRight className="w-9 h-9 text-white" />
                  </div>
                );
              }}
              swipeable={true}
              className="px-1 sm:px-10"
            >
              {projectData.map((currEle, index) => {
                return <ProjectsCard data={currEle} />;
              })}
            </Carousel>
          </Slide>
        </div>
      </Container>
    </div>
  );
}
