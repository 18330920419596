/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Container from "../../Container";
import practical from "../../../assets/images/practical.png";
import linkedIn from "../../../assets/images/linkedin.png";
import graph from "../../../assets/images/graph-image.png";
import { Fade } from "react-awesome-reveal";

export default function Offerings() {
  return (
    <div className="w-100% h-full pb-10 overflow-hidden" data-aos="fade-up">
      <Fade>
        <Container>
          <div className="flex flex-col sm:flex-col sm:w-[90%] md:w-full sm:m-auto md:flex-col sm:mt-20 mt-10">
            <div className="flex sm:gap-4 gap-3 flex-col sm:flex-col md:flex-row md:gap-3 lg:gap-4 md:mt-5 sm:mt-3 lg:mt-3 w-[100%] mt-3 ">
              <div className="flex flex-col sm:flex-col md:flex-col radial-gradient lg:gap-4 md:gap-3 sm:gap-2 rounded-2xl md:w-[30%] h-auto ">
                <p className="text-white ml-5 text-center  sm:text-2xl md:w-auto sm:ml-5 m-auto mt-10 sm:m-auto sm:mt-10 font-semibold lg:text-xl xl:text-2xl md:text-base md:text-center md:mt-10 body lg:ml-5 lg:mt-10 ">
                  <a
                    href="https://biacademy.in/courses/zero-to-advance-power-bi"
                    target="_blank"
                  >
                    {" "}
                    Power BI 1:1 Training
                  </a>
                </p>
                <p className="text-white m-auto mt-3 ml-5 text-sm sm:m-auto sm:mt-7  sm:text-base sm:w-auto sm:ml-5 xl:text-base lg:text-sm md:w-auto md:ml-5 md:text-xs lg:w-auto font-normal body lg:ml-5 lg:-mt-3">
                  <a
                    href="https://biacademy.in/courses/zero-to-advance-power-bi"
                    target="_blank"
                  >
                    Learn Power BI from industry experts with our online and
                    corporate training programs. Whether you're a beginner or an
                    experienced user, we offer 1:1 personal training and mock
                    interviews to help you succeed in your career.
                  </a>
                </p>
                <img
                  src={practical}
                  alt={practical}
                  className=" lg:m-auto md:m-auto sm:w-[60%] w-[60%] xl:w-[90%] m-auto sm:m-auto md:w-full"
                />
              </div>
              <div className="flex flex-col sm:gap-3 lg:gap-4 md:gap-3 md:w-[70%] lg:w-[80%] gap-3 ">
                <div className="flex flex-row gap-3 rounded-3xl radial-gradient h-[50%] sm:h-[100%] sm:w-[100%] overflow-hidden md:h-[80%] sm:pb-3 md:pb-auto pb-5 ">
                  <img
                    src={linkedIn}
                    alt={linkedIn}
                    className=" md:my-auto lg:ml-4 p-4 xl:w-[50%] lg:w-[50%] md:w-[50%] hidden md:block mt-3 sm:mt-4 sm:ml-4 sm:m-auto"
                  />

                  <div className="flex flex-col m-auto px-2 mt-3 sm:pb-4 pb-4 md:pb-0 md:m-auto gap-4">
                    <p className="text-white text-lg lg:text-xl xl:text-2xl md:text-base sm:text-xl sm:mt-4 sm:ml-5 md:mt-0 md:ml-0 mt-4 ml-5 text-left font-semibold">
                      <a
                        href="https://biacademy.in/courses/python-for-data-analytics-from-scratch"
                        target="_blank"
                      >
                        Python 1:1 Training
                      </a>
                    </p>
                    <a
                      href="https://biacademy.in/courses/python-for-data-analytics-from-scratch"
                      target="_blank"
                    >
                      <p className="text-white text-left text-[10px] md:text-xs xl:text-base sm:mt-4 sm:ml-5 md:mt-0 md:ml-0 mt-4 ml-5 lg:text-sm sm:text-base text-sm font-normal">
                        Learn Python in a personalized and engaging way. Whether
                        you have some or no programming experience, this course
                        is designed to meet your individual needs and goals. We
                        guide you through the fundamentals of Python to help you
                        build a strong foundation in the language. So if you're
                        looking for a dynamic and effective way to learn Python,
                        this 1:1 course is the perfect choice."
                      </p>
                    </a>
                  </div>
                </div>

                <div className="flex flex-col md:flex-row gap-3 md:gap-3 md:h-[40%]">
                  <img
                    src={graph}
                    alt={graph}
                    className="md:w-[42%] hidden md:block"
                  />

                  <div className="flex flex-col p-4 px-2 radial-gradient rounded-3xl gap-3 md:gap-0 m-auto h-[100%] md:w-[58%]">
                    <p className="text-white font-semibold mt-4 text-base ml-5 sm:mt-4 md:mt-0 sm:ml-5 sm:text-xl xl:text-2xl lg:text-xl md:text-base m-auto md:text-center">
                      <a href="https://biacademy.in/freelance" target="_blank">
                        {" "}
                        Freelance - Power BI Projects
                      </a>
                    </p>
                    <p className="text-white text-sm pb-4 xl:text-base sm:pb-4 sm:ml-5 md:pb-0 lg:text-sm md:text-xs m-auto ml-5 ">
                      <a href="https://biacademy.in/freelance" target="_blank">
                        Need help with your Power BI projects? Look no further!
                        Our experienced freelancers offer daily, weekly, and
                        monthly plans to fit your needs. From data modeling to
                        report design, we'll help you bring your data to life
                        and make informed business decisions
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </div>
  );
}
